.react-datepicker__header {
  background: none;
}

.react-datepicker {
  border-color: #dbdbdb;
}

.react-datepicker__header {
  border-bottom: 1px solid #dbdbdb;
}
