section.hero {
  background-color: #fff;
  padding: 0 18px;
  .hero-body {
    flex-grow: 1;
    flex-shrink: 0;
    padding: 1rem 0 0.5rem 0;
  }
}

.main-section {
  padding: 0.5rem 1.5rem 0rem 1.5rem;
}
