$bodyColor: #ed5d1e;
$backfaceColor: #00eaff;

#slot-page {
  margin: 0 auto;
}

#slot-content {
  padding: 20px;
}

#slot-machine {
  border-radius: 10px;
  box-shadow: 0 1px 100px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.8);
  height: 150px;
  margin: 50px auto;
  position: relative;
  width: 200px;
}

#slot-body {
  background: $bodyColor;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 2px solid $bodyColor;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: inset 0 0 25px rgba(255, 255, 255, 0.6);
  height: 150px;
  margin: 50px auto;
  position: relative;
  width: 200px;
  z-index: 2;
}

#slot-frame {
  background: #1b1b1b;
  background: -moz-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #1b1b1b),
    color-stop(8%, #383838),
    color-stop(20%, #3a3a3a),
    color-stop(47%, #0a0a0a),
    color-stop(50%, #010101),
    color-stop(54%, #0d0d0d),
    color-stop(100%, #444444)
  );
  background: -webkit-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -o-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -ms-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: linear-gradient(
    to bottom,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.3),
    0 1px 1px rgba(255, 255, 255, 0.5), 0 -1px 1px rgba(255, 255, 255, 0.2),
    inset 0 -2px 15px #000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid $bodyColor;
  height: 90px;
  left: 50%;
  margin-left: -85px;
  position: absolute;
  top: 10px;
  width: 171px;
  z-index: 1;
}

#slot-wheels {
  height: 86px;
  left: 15px;
  overflow: hidden;
  position: absolute;
  top: 12px;
  width: 167px;
  z-index: 2;
}

#slot-wheels .wheel {
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  height: 86px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 44px;
}

#slot-wheels .wheel img {
  left: 0;
  position: absolute;
  top: -4px;
  width: 44px;
  z-index: 1;
}

#wheel1 {
  left: 11px;
}
#wheel2 {
  left: 61px;
}
#wheel3 {
  left: 111px;
}

#slot-wheels .wheel .overlay {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.25) 80%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 1)),
    color-stop(25%, rgba(0, 0, 0, 0.25)),
    color-stop(50%, rgba(0, 0, 0, 0)),
    color-stop(80%, rgba(0, 0, 0, 0.25)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.25) 80%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.25) 80%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.25) 80%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.25) 25%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.25) 80%,
    rgba(0, 0, 0, 1) 100%
  );
  border: 1px solid #000;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

#slot-block {
  display: none;
  height: 113%;
  left: 0;
  position: absolute;
  top: -18px;
  width: 225px;
  z-index: 4;
}

#slot-display {
  background: #1b1b1b;
  background: -moz-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #1b1b1b),
    color-stop(8%, #383838),
    color-stop(20%, #3a3a3a),
    color-stop(47%, #0a0a0a),
    color-stop(50%, #010101),
    color-stop(54%, #0d0d0d),
    color-stop(100%, #444444)
  );
  background: -webkit-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -o-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: -ms-linear-gradient(
    top,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  background: linear-gradient(
    to bottom,
    #1b1b1b 0%,
    #383838 8%,
    #3a3a3a 20%,
    #0a0a0a 47%,
    #010101 50%,
    #0d0d0d 54%,
    #444444 100%
  );
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0 0 16px rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 16px rgba(255, 255, 255, 0.3),
    0 1px 1px rgba(255, 255, 255, 0.5), 0 -1px 1px rgba(255, 255, 255, 0.2),
    inset 0 -2px 15px #000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid $bodyColor;
  bottom: 10px;
  height: 28px;
  left: 50%;
  letter-spacing: 3px;
  line-height: 25px;
  margin-left: -85px;
  font-family: "Courier New", Courier, monospace;
  font-size: 18px;
  text-align: right;
  position: absolute;
  width: 171px;
  z-index: 2;
}

#slot-display div {
  position: absolute;
  right: 4px;
  top: 1px;
}

#slot-credits {
  color: #78ff00;
  font-weight: bold;
  z-index: 2;
}

#slot-zeros {
  color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

#slot-overlay {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.1)),
    color-stop(50%, rgba(0, 0, 0, 0.15)),
    color-stop(51%, rgba(0, 0, 0, 0)),
    color-stop(100%, rgba(0, 0, 0, 0))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0) 51%,
    rgba(0, 0, 0, 0) 100%
  );
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

#slot-overlay-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  height: 46%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

#slot-trigger {
  cursor: pointer;
  height: 85px;
  right: -40px;
  top: 30px;
  position: absolute;
  width: 37px;
}

#slot-trigger div {
  position: absolute;
  top: 0;
}

#slot-trigger .ring1 {
  background: #282828;
  background: -moz-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #282828),
    color-stop(14%, #959595),
    color-stop(37%, #d1d1d1),
    color-stop(49%, #bababa),
    color-stop(67%, #959595),
    color-stop(100%, #212121)
  );
  background: -webkit-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -o-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -ms-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: linear-gradient(
    to bottom,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  height: 100%;
  left: 0;
  width: 8px;
  z-index: 2;
}

#slot-trigger .ring1 .shadow {
  background: -moz-linear-gradient(
    top,
    rgba(149, 149, 149, 0.2) 0%,
    rgba(13, 13, 13, 0.18) 46%,
    rgba(1, 1, 1, 0.18) 50%,
    rgba(10, 10, 10, 0.18) 53%,
    rgba(78, 78, 78, 0.17) 76%,
    rgba(56, 56, 56, 0.16) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(149, 149, 149, 0.2)),
    color-stop(46%, rgba(13, 13, 13, 0.18)),
    color-stop(50%, rgba(1, 1, 1, 0.18)),
    color-stop(53%, rgba(10, 10, 10, 0.18)),
    color-stop(76%, rgba(78, 78, 78, 0.17)),
    color-stop(87%, rgba(56, 56, 56, 0.16)),
    color-stop(100%, rgba(27, 27, 27, 0.15))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(149, 149, 149, 0.2) 0%,
    rgba(13, 13, 13, 0.18) 46%,
    rgba(1, 1, 1, 0.18) 50%,
    rgba(10, 10, 10, 0.18) 53%,
    rgba(78, 78, 78, 0.17) 76%,
    rgba(56, 56, 56, 0.16) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(149, 149, 149, 0.2) 0%,
    rgba(13, 13, 13, 0.18) 46%,
    rgba(1, 1, 1, 0.18) 50%,
    rgba(10, 10, 10, 0.18) 53%,
    rgba(78, 78, 78, 0.17) 76%,
    rgba(56, 56, 56, 0.16) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(149, 149, 149, 0.2) 0%,
    rgba(13, 13, 13, 0.18) 46%,
    rgba(1, 1, 1, 0.18) 50%,
    rgba(10, 10, 10, 0.18) 53%,
    rgba(78, 78, 78, 0.17) 76%,
    rgba(56, 56, 56, 0.16) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(149, 149, 149, 0.2) 0%,
    rgba(13, 13, 13, 0.18) 46%,
    rgba(1, 1, 1, 0.18) 50%,
    rgba(10, 10, 10, 0.18) 53%,
    rgba(78, 78, 78, 0.17) 76%,
    rgba(56, 56, 56, 0.16) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  height: 50%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 10px;
  z-index: 1;
}

#slot-trigger .ring2 {
  background: #282828;
  background: -moz-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #282828),
    color-stop(14%, #959595),
    color-stop(37%, #d1d1d1),
    color-stop(49%, #bababa),
    color-stop(67%, #959595),
    color-stop(100%, #212121)
  );
  background: -webkit-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -o-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: -ms-linear-gradient(
    top,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  background: linear-gradient(
    to bottom,
    #282828 0%,
    #959595 14%,
    #d1d1d1 37%,
    #bababa 49%,
    #959595 67%,
    #212121 100%
  );
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  -moz-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.8);
  height: 80%;
  left: 8px;
  overflow: hidden;
  top: 10%;
  width: 10px;
  z-index: 1;
}

#slot-trigger .ring2 .shadow {
  background: -moz-linear-gradient(
    top,
    rgba(149, 149, 149, 0.15) 0%,
    rgba(13, 13, 13, 0.15) 46%,
    rgba(1, 1, 1, 0.15) 50%,
    rgba(10, 10, 10, 0.15) 53%,
    rgba(78, 78, 78, 0.15) 76%,
    rgba(56, 56, 56, 0.15) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(149, 149, 149, 0.15)),
    color-stop(46%, rgba(13, 13, 13, 0.15)),
    color-stop(50%, rgba(1, 1, 1, 0.15)),
    color-stop(53%, rgba(10, 10, 10, 0.15)),
    color-stop(76%, rgba(78, 78, 78, 0.15)),
    color-stop(87%, rgba(56, 56, 56, 0.15)),
    color-stop(100%, rgba(27, 27, 27, 0.15))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(149, 149, 149, 0.15) 0%,
    rgba(13, 13, 13, 0.15) 46%,
    rgba(1, 1, 1, 0.15) 50%,
    rgba(10, 10, 10, 0.15) 53%,
    rgba(78, 78, 78, 0.15) 76%,
    rgba(56, 56, 56, 0.15) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(149, 149, 149, 0.15) 0%,
    rgba(13, 13, 13, 0.15) 46%,
    rgba(1, 1, 1, 0.15) 50%,
    rgba(10, 10, 10, 0.15) 53%,
    rgba(78, 78, 78, 0.15) 76%,
    rgba(56, 56, 56, 0.15) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(149, 149, 149, 0.15) 0%,
    rgba(13, 13, 13, 0.15) 46%,
    rgba(1, 1, 1, 0.15) 50%,
    rgba(10, 10, 10, 0.15) 53%,
    rgba(78, 78, 78, 0.15) 76%,
    rgba(56, 56, 56, 0.15) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(149, 149, 149, 0.15) 0%,
    rgba(13, 13, 13, 0.15) 46%,
    rgba(1, 1, 1, 0.15) 50%,
    rgba(10, 10, 10, 0.15) 53%,
    rgba(78, 78, 78, 0.15) 76%,
    rgba(56, 56, 56, 0.15) 87%,
    rgba(27, 27, 27, 0.15) 100%
  );
  -moz-border-radius: 0 2px 2px 0;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  height: 50%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 10px;
  z-index: 1;
}

#slot-trigger .arm {
  background: #0d0d0d;
  background: -moz-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, #0d0d0d),
    color-stop(47%, #4e4e4e),
    color-stop(87%, #383838),
    color-stop(100%, #1b1b1b)
  );
  background: -webkit-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: -o-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: -ms-linear-gradient(
    left,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  background: linear-gradient(
    to right,
    #0d0d0d 0%,
    #4e4e4e 47%,
    #383838 87%,
    #1b1b1b 100%
  );
  -moz-border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.8);
  height: 50%;
  left: 10px;
  top: -25px;
  width: 6px;
  z-index: 3;
  overflow: visible !important;
}

#slot-trigger .arm .knob {
  background: #ff6363;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, #ff6363),
    color-stop(100%, #cf0404)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    #ff6363 0%,
    #cf0404 100%
  );
  background: radial-gradient(ellipse at center, #ff6363 0%, #cf0404 100%);
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  height: 16px;
  left: -7px;
  top: -15px;
  width: 20px;
  z-index: 4;
}

#slot-trigger .arm-shadow {
  background: #000;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  bottom: 66px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
  height: 6px;
  left: 9px;
  top: auto;
  width: 8px;
  z-index: 2;
}

#slotTrigger.slotTriggerDown {
  background-position: 0px -17px;
}
#slotTrigger.slotTriggerDisabled {
  background-position: 0px -34px;
  display: none;
}

#slot-glaze-bottom {
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(45%, rgba(255, 255, 255, 0.1)),
    color-stop(100%, rgba(255, 255, 255, 0.1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.1) 100%
  );
  bottom: 2px;
  border-radius: 0 0 5px 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  left: 3px;
  height: 14px;
  position: absolute;
  width: 190px;
}

#slot-details {
  height: 150px;
  left: 0;
  position: absolute;
  top: 0;
  width: 200px;
  z-index: 1;
}

#slot-top {
  background: $bodyColor;
  border-radius: 10px 10px 0 0;
  border: 2px solid $bodyColor;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.8),
    inset 0 0 25px rgba(255, 255, 255, 0.2);
  height: 12px;
  left: 50%;
  margin-left: -98px;
  position: absolute;
  top: -3px;
  width: 196px;
}

#slot-bottom {
  background: $bodyColor;
  border-radius: 0 0 10px 10px;
  border: 2px solid $bodyColor;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.8),
    inset 0 0 25px rgba(255, 255, 255, 0.2);
  height: 12px;
  left: 50%;
  margin-left: -98px;
  position: absolute;
  bottom: -3px;
  width: 196px;
}

.wheel-finish {
  transition: top 1s cubic-bezier(0, 1, 0.5, 1) 0s;
  #wheel1 & {
    transition-duration: 1.2s;
  }
  #wheel2 & {
    transition-duration: 0.8s;
  }
  #wheel3 & {
    transition-duration: 1s;
  }
}
.wheel-spining {
  opacity: 0;
  transition: opacity 0s;
  &.is-spining {
    opacity: 1;
  }
}
.wheel-finish,
.wheel-spining {
  #wheel1 & {
    transition-delay: 0.2s;
    animation-delay: 0.2s;
  }
  #wheel2 & {
    transition-delay: 0.8s;
    animation-delay: 0.8s;
  }
  #wheel3 & {
    transition-delay: 1.5s;
    animation-delay: 1.5s;
  }
}

@keyframes spining {
  0% {
    top: -4px - 44px * 20;
  }
  100% {
    top: -4px - 44px * 0;
  }
}

.wheel-spining {
  animation: 1s linear infinite 0s normal spining;
}

.arm {
  transition: top 400ms ease 0s, height 400ms ease 0s;
}
.arm .knob {
  transition: top 400ms ease 0s, height 400ms ease 0s;
}
.arm-shadow {
  transition: top 380ms ease 0s;
}
.ring1 .shadow,
.ring2 .shadow {
  transition: top 400ms ease 0s, opacity 400ms ease 0s;
}

@keyframes arm {
  50% {
    top: 45px;
    height: 2%;
  }
}
@keyframes armKnob {
  50% {
    top: -20px;
    height: 20px;
  }
}
@keyframes armShadow {
  50% {
    top: "40px";
  }
}
@keyframes armRing {
  50% {
    top: 50%;
    opacity: 1;
  }
}
.arm-down #slot-trigger {
  .arm {
    animation: 1s ease 1 0s normal arm;
  }
  .arm .knob {
    animation: 1s ease 1 0s normal armKnob;
  }
  .arm-shadow {
    animation: 1s ease 1 0s normal armShadow;
  }
  .ring1 .shadow,
  .ring2 .shadow {
    animation: 1s ease 1 0s normal armRing;
  }
}

.slot-backface {
  animation: 1s linear infinite 0s normal slotBackface;
}

@keyframes slotBackface {
  0% {
    background: repeating-conic-gradient(
      transparent,
      transparent 8deg,
      $backfaceColor 8.1deg 14.9deg,
      transparent 15deg
    );
  }
  50% {
    background: repeating-conic-gradient(
      transparent,
      transparent 0deg,
      $backfaceColor 0.1deg 6.9deg,
      transparent 7deg,
      transparent 15deg
    );
  }
  100% {
    background: repeating-conic-gradient(
      transparent,
      transparent 8deg,
      $backfaceColor 8.1deg 14.9deg,
      transparent 15deg
    );
  }
}
