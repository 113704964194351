@media screen and (min-width: 800px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

.navbar-link:not(.is-arrowless)::after {
  border: 3px solid transparent;
  border-radius: 2px;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.625em;
  margin-top: -0.4375em;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.625em;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fff;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 30;
  top: 0;
}

.navbar-brand {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem;
}

.navbar-brand a.navbar-item {
  &:focus,
  &:hover {
    background-color: transparent;
  }
}

.navbar-menu {
  display: none;
}

.navbar-item,
.navbar-link {
  color: #4a4a4a;
  display: block;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;
}

.navbar-item .icon:only-child,
.navbar-link .icon:only-child {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

a.navbar-item,
.navbar-link {
  cursor: pointer;
}

a.navbar-item {
  &:hover {
    background-color: #333;
    color: #fff;
  }
}

a.navbar-item {
  &.is-active {
    background-color: #fafafa;
    color: #3273dc;
    cursor: default;
  }
}

.navbar-link {
  &.is-active,
  &:focus,
  &:focus-within,
  &:hover {
    background-color: #fafafa;
    color: #3273dc;
  }
}

.navbar-item {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;

  img {
    max-height: 1.75rem;
  }

  &.has-dropdown {
    padding: 0;
  }
}

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1;
}

.navbar-link:not(.is-arrowless) {
  padding-right: 2.5em;

  &::after {
    border-color: #3273dc;
    margin-top: -0.375em;
    right: 1.125em;
  }
}

.navbar-dropdown {
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;

  .navbar-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.navbar-divider {
  background-color: #f5f5f5;
  border: none;
  display: none;
  height: 2px;
  margin: 0.5rem 0;
}

@media screen and (max-width: 799px) {
  .navbar-brand .navbar-item {
    align-items: center;
    display: flex;
  }

  .navbar-link::after {
    display: none;
  }

  .navbar-menu {
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
    padding: 0.5rem 0;
    &.is-active {
      display: block;
    }
  }

  .navbar {
    &.is-fixed-top .navbar-menu {
      -webkit-overflow-scrolling: touch;
      max-height: calc(100vh - 3.25rem);
      overflow: auto;
    }
  }
}

@media screen and (min-width: 800px) {
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex;
  }

  .navbar {
    min-height: 3.25rem;

    &.is-transparent {
      a.navbar-item {
        &:focus,
        &:hover {
          background-color: transparent !important;
        }
      }

      .navbar-link {
        &:focus,
        &:hover {
          background-color: transparent !important;
        }
      }

      .navbar-item.has-dropdown {
        &.is-hoverable {
          &:focus .navbar-link,
          &:focus-within .navbar-link,
          &:hover .navbar-link {
            background-color: transparent !important;
          }
        }
      }

      .navbar-dropdown a.navbar-item {
        &:focus,
        &:hover {
          background-color: #f5f5f5;
          color: #0a0a0a;
        }
      }
    }
  }

  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex;
  }

  .navbar-item {
    display: flex;

    &.has-dropdown {
      align-items: stretch;
    }

    &.has-dropdown-up {
      .navbar-link::after {
        transform: rotate(135deg) translate(0.25em, -0.25em);
      }

      .navbar-dropdown {
        border-bottom: 2px solid #dbdbdb;
        border-radius: 6px 6px 0 0;
        border-top: none;
        bottom: 100%;
        box-shadow: 0 -8px 8px rgba(10, 10, 10, 0.1);
        top: auto;
      }
    }
    &.is-hoverable {
      &:focus .navbar-dropdown,
      &:focus-within .navbar-dropdown,
      &:hover .navbar-dropdown {
        display: block;
      }
    }
  }
  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
  }

  .navbar-end {
    justify-content: flex-end;
    margin-left: auto;
  }

  .navbar-dropdown {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 2px solid #dbdbdb;
    box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
    display: none;
    font-size: 0.875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 20;

    .navbar-item {
      padding: 0.375rem 1rem;
      white-space: nowrap;
    }

    a.navbar-item {
      padding-right: 3rem;

      &:focus,
      &:hover {
        background-color: #f5f5f5;
        color: #0a0a0a;
      }
    }
  }
  .navbar-divider {
    display: block;
  }
}

nav.navbar {
  box-shadow: 0 1px 0 rgba(24, 28, 33, 0.04);

  .navbar-item {
    &.has-user-avatar .is-user-avatar {
      margin-right: 0.75rem;
      display: inline-flex;
      width: 1.75rem;
      height: 1.75rem;
      .is-user-avatar {
        img {
          margin: 0 auto;
          border-radius: 290486px;
        }
      }
    }

    &.has-divider {
      border-right: 1px solid rgba(219, 219, 219, 0.25);
    }

    &.no-left-space {
      padding-left: 0;
    }

    &.has-dropdown {
      padding-right: 0;
      padding-left: 0;

      .navbar-link {
        padding-right: 1.125rem;
        padding-left: 1.125rem;
      }
    }
  }
}

@media screen and (max-width: 799px) {
  nav.navbar {
    display: flex;
    padding-right: 0;

    .navbar-brand {
      flex: 1;
      &.is-right {
        flex: none;
      }
    }
    .navbar-menu {
      position: absolute;
      width: 100vw;
      padding-top: 0;
      top: 3.25rem;
      left: 0;

      .navbar-item {
        .icon:first-child {
          margin-right: 0.75rem;
        }

        &.has-dropdown > .navbar-link {
          background-color: #f5f5f5;

          .icon:last-child {
            display: none;
          }
        }

        &.has-user-avatar > .navbar-link {
          display: flex;
          align-items: center;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  nav.navbar .navbar-item {
    padding-right: 1.125rem;
    padding-left: 1.125rem;

    &:not(.is-desktop-icon-only) .icon:first-child {
      margin-right: 0.75rem;
    }

    &.is-desktop-icon-only span:not(.icon) {
      display: none;
    }
  }
}

@media screen and (min-width: 800px) {
  nav.navbar {
    padding-left: 14rem;
  }

  nav.navbar,
  body {
    transition: padding-left 250ms ease-in-out 50ms;
  }

  aside.aside {
    display: block;
    width: 14rem;

    .menu-list {
      .icon {
        width: 3rem;
      }

      span.menu-item-label {
        display: inline-block;
      }
    }
  }
}

aside.aside {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  height: 100vh;
  padding: 0;
  box-shadow: none;
  background: #2e323a;

  .aside-tools {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #17191e;
    color: #fff;
    line-height: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.75rem;
    flex: 1;

    .icon {
      margin-right: 0.75rem;
    }
  }

  .menu-list li {
    a.has-dropdown-icon {
      position: relative;
      padding-right: 3rem;

      .dropdown-icon {
        position: absolute;
        top: 0.5rem;
        right: 0;
      }
    }

    ul {
      display: none;
      border-left: 0;
      background-color: #282c33;
      padding-left: 0;
      margin: 0 0 0.75rem;

      li a {
        padding: 0.75rem 0 0.75rem 0.75rem;
        font-size: 0.95rem;

        &.has-icon {
          padding-left: 0;
        }

        &.is-active:not(:hover) {
          background: transparent;
        }
      }
    }
  }

  .menu-label {
    padding: 0 0.75rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 799px) {
  body {
    overflow-x: hidden;
  }
  nav.navbar {
    transition: margin-left 250ms ease-in-out 50ms;
    width: 100vw;
    &.has-aside {
      margin-left: 15rem;
    }
  }
  aside.aside {
    transition: left 250ms ease-in-out 50ms;
    width: 15rem;
    display: block;
    left: -15rem;

    &.has-aside {
      left: 0;
    }

    .image img {
      max-width: 4.95rem;
    }

    .menu-list {
      a {
        .icon {
          width: 3rem;
        }
        span.menu-item-label {
          display: inline-block;
        }
      }
    }
  }
}

nav.navbar .icon i {
  height: inherit;
}

@media screen and (max-width: 799px) {
  .navbar-item:not(.has-dropdown),
  .navbar-link {
    display: flex;
  }
}

aside.aside .menu-list li a.has-icon {
  display: flex;

  .icon i {
    height: auto;
  }

  .menu-item-label {
    line-height: 1.5rem;
    height: 1.5rem;
  }
}

.dropdown {
  display: inline-flex;
  position: relative;
  vertical-align: top;

  &.is-hoverable:hover .dropdown-menu {
    display: block;
  }
}

.dropdown-menu {
  display: none;
  left: 0;
  min-width: 12rem;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  z-index: 20;
}

.dropdown-content {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.dropdown-item {
  color: #4a4a4a;
  display: block;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.375rem 1rem;
  position: relative;
}

a.dropdown-item,
button.dropdown-item {
  padding-right: 3rem;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}

a.dropdown-item:hover,
button.dropdown-item:hover {
  background-color: #f5f5f5;
  color: #0a0a0a;
}

.dropdown-divider {
  background-color: #dbdbdb;
  border: none;
  display: block;
  height: 1px;
  margin: 0.5rem 0;
}

.menu {
  font-size: 1rem;
}

.menu-list {
  line-height: 1.25;

  a {
    border-radius: 0;
    color: #bbb;
    display: block;
    padding: 0.5rem 0;

    // &:hover {
    //   background-color: #262930;
    //   color: #fff;
    // }
  }

  li ul {
    border-left: 1px solid #dbdbdb;
    margin: 0.75em;
    padding-left: 0.75em;
  }
}

.menu-label {
  color: #666f81;
  font-size: 0.75em;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:not(:first-child) {
    margin-top: 1em;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
