.policy {
  .modal-dialog {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal-content {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .modal {
    overflow: hidden !important;
  }
}
