.section {
  margin: 0 auto;
}

.column {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1.5rem;
}

.columns {
  align-items: center;
  @media screen and (min-width: 800px) {
    display: flex;
  }
}
