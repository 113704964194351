.ticket {
  padding: 0.3rem;
  box-shadow: 1px 1px 6px rgba(10, 5, 0, 0.5);
  color: #433;
  max-width: 480px;
  border: 1px solid #1e05001f;
  position: relative;
  background: transparent;
  z-index: 1;
  &::before {
    background: url(/assets/metal.low.jpg);
    background-size: cover;
    filter: opacity(80%);
    content: " ";
    z-index: -1;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.ticket-inner {
  padding: 0 0.7rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid rgba(30, 5, 0, 0.5);
  border-radius: 2px;
  text-align: center;
}
.ticket-left {
  width: 65%;
  border-right: 1px dashed #976;
  padding: 0.7rem 1rem 0.7rem 0;
  font-size: 80%;
}
.ticket-code {
  font-weight: bold;
  font-size: 1.8em;
  line-height: 1.2;
  padding: 0.5rem 0;
}
.ticket-name {
  border-top: 1px solid #666;
  padding: 0.4em 0 0 0;
  line-height: 1.2;
  font-size: 1em;
  padding: 0.5rem 0;
}
.ticket-right {
  width: 30%;
  padding: 0.7rem 0;
  line-height: 1.2;
}
.ticket-price {
  font-weight: bold;
  font-size: 180%;
}
.ticket-ppp {
  border-bottom: 1px solid #666;
  padding-bottom: 6px;
  margin-bottom: 6px;
}

@media screen and (max-width: 460px) {
  .ticket {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 420px) {
  .ticket {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 380px) {
  .ticket {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 340px) {
  .ticket {
    font-size: 0.6 em;
  }
}
