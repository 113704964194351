$selectorButtonColor: #4c68a4;

.numberSelectorOption {
  padding: 4px 9px;
  @media screen and (min-width: 420px) {
    padding: 4px 12px;
  }
  border-top: 1px solid $selectorButtonColor;
  border-bottom: 1px solid $selectorButtonColor;
  border-left: 1px solid $selectorButtonColor;
  color: $selectorButtonColor;
  // margin-right: 8px;
  // border-radius: 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &.active {
    color: #fff;
    background-color: $selectorButtonColor;
  }
  line-height: 2.5em;
  &:first-of-type {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-of-type {
    border-right: 1px solid $selectorButtonColor;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .has-error & {
    background-color: rgba(255, 56, 96, 0.05);
    &.active {
      color: rgba(255, 56, 96, 0.05);
    }
  }
}
.text {
  display: block;
  border: none !important;
  color: #333;
  white-space: nowrap;
  &:first-of-type {
    align-self: start;
  }
  &:last-of-type {
    align-self: end;
  }
}
